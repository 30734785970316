<template>
  <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.75 18.75L1 10L9.75 1.25" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconChevronLeft"
}
</script>
