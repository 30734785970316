<template>
  <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.25 1.25L10 10L1.25 18.75" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconChevronRight"
}
</script>

<style scoped>

</style>